<template>
    <div class="INvigilator">
        <Banner tit1='远程智慧监考' tit2='Remote intelligent invigilator' url='1'></Banner>
        <div class="Notice">
            <el-card class="box_card">
               <h2>远程智慧监考平台</h2>
               <p>网培师各模块的评测考试全部通过在线方式进行，每周定时开放。</p>
               <p>正式考试的时候，所有报名参加评测的人员都需要经过远程身份验证，并在带摄像头的PC电脑上进行考试。</p>
               <p>网培师的各个评测模块，每周都将定时开放考试，报名后可自主选择考试时间。</p>
               <p>此外，应试者可以在不同的日期参加不同的评测模块考试。</p>
               <p>本项目的远程考试系统采用网班教育提供的“智慧监考”平台，报名后，需在指定时间从PC电脑登录进考试系统，准备可自己的身份证信息，平台将进行AI人脸识别，远程确认身份后，开始考试。开始过程中，会随机进行取样，确保考试的公平公</p>
            </el-card>        
        </div>

        <div class="flowchart">
            <h2>参与评测考试流程图</h2>
            <el-card class="box_card" v-for="(item,index) in flowchartlist" :key="index">
               <el-row class="flex-col-center" :gutter=30 >
                   <el-col :span="20" class="flex-col-center">
                       <img :src=item.url alt="">
                       <span>{{item.tit}}</span>
                   </el-col>
                   <el-col :span="4">
                       <img src="../../assets/images/guide/arrow.png" alt="" class="arrow_img">
                   </el-col>
               </el-row>
            </el-card>        
        </div>
    </div>
</template>
<script>
import Banner from './layout/Banner'
export default {
    data(){
        return{
            flowchartlist:[
                {
                    url:require('../../assets/images/guide/img2.png'),
                    tit:'在线报名（付费）'
                },
                {
                    url:require('../../assets/images/guide/img7.png'),
                    tit:'选定日期PC电脑登录考试系统'
                },
                {
                    url:require('../../assets/images/guide/img8.png'),
                    tit:'完成人脸远程身份验证'
                },
                {
                    url:require('../../assets/images/guide/img3.png'),
                    tit:'开始并完成评测考试'
                },
            ]
        }
    },
    components:{
        Banner
    }
}
</script>
<style lang="less" scoped>
    .INvigilator{
        width:100%;
        h2{
            font-size: 26px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            line-height: 32px; 
        }
        .Notice{
            width: 100%;
            background:url('../../assets/images/home/why.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            padding: 40px 0px;
            p{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #7C8087;
                line-height: 20px;
                margin-top: 10px;
            }
            .el-card{
                width: 85vw;
                margin:0px auto;
            }
        }
        .flowchart{
            padding: 20px 0px;
            width: 100%;
            h2{
                padding-left: calc(7.5vw + 20px);
            }
            .box_card{
                width: 85vw;
                margin: 10px auto;
                box-shadow: 0px 2px 21px 0px rgba(134, 99, 69, 0.14);
                border-radius: 6px;
                img{
                    width: 10vw;
                }
                .arrow_img{
                    width: 16px;
                }
                span{
                    padding-left: 6px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 500;
                    color: #333333;
                    line-height: 22px;
                }
            }
        }
    }
</style>